import { Collapse, FormControlLabel, Switch } from '@mui/material'
import { useState } from 'react'
import { colors } from 'theme/colors'
import { Button, Stack, Text } from 'ui/baseComponents'
import DailyCategoryFilter from 'ui/clinicianScreens/Patient/Daily/components/DailyCategoryFilter/DailyCategoryFilter'
import { SECTION_TITLES } from 'ui/clinicianScreens/Patient/Daily/consts'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import useBoundStore from 'domains/zustand/store'

const DailyFilters = ({
  hasTremorData,
  hasDyskinesiaData,
  hasMedicationEvents,
  hasSymptomEvents,
  hasNotesEvents,
  hasCheckinEvents,
  isTremorFilterActive,
  setIsTremorFilterActive,
  isDyskinesiaFilterActive,
  setIsDyskinesiaFilterActive,
  isMedicationFilterActive,
  setIsMedicationFilterActive,
  medicationInitialFilters,
  medicationActiveFilters,
  setMedicationActiveFilters,
  isSymptomFilterActive,
  setIsSymptomFilterActive,
  symptomInitialFilters,
  symptomActiveFilters,
  setSymptomActiveFilters,
  // isSleepFilterActive,
  // setIsSleepFilterActive,
  isCheckinFilterActive,
  setIsCheckinFilterActive,
  isNotesFilterActive,
  setIsNotesFilterActive,
}) => {
  const dailyViewCombinedMedications = useBoundStore(
    (state) => state.dailyViewCombinedMedications,
  )
  const toggleDailyViewCombinedMedications = useBoundStore(
    (state) => state.toggleDailyViewCombinedMedications,
  )
  const dailyViewFixedYAxis = useBoundStore(
    (state) => state.dailyViewFixedYAxis,
  )
  const toggleDailyViewFixedYAxis = useBoundStore(
    (state) => state.toggleDailyViewFixedYAxis,
  )
  const dailyViewHideEmptyDays = useBoundStore(
    (state) => state.dailyViewHideEmptyDays,
  )
  const toggleDailyViewHideEmptyDays = useBoundStore(
    (state) => state.toggleDailyViewHideEmptyDays,
  )

  const [showFilters, setShowFilters] = useState(true)
  const toggleFilters = () => setShowFilters(!showFilters)
  const trigger = useScrollTrigger({ threshold: 860 })

  return (
    <Stack
      sx={{
        backgroundColor: colors.COOL[100],
        minHeight: '80vh',
      }}
      data-cy="daily-view-filters"
    >
      <Stack
        sx={{
          position: 'sticky',
          top: trigger ? 0 : '73px',
          transition: 'all 0.3s ease',
        }}
      >
        <Stack sx={{ padding: '1rem' }}>
          <Button
            onClick={toggleFilters}
            type="secondary"
            size="large"
            data-cy={
              showFilters
                ? 'daily-view-button-filters-expanded'
                : 'daily-view-button-filters-collapsed'
            }
          >
            {showFilters ? (
              <KeyboardDoubleArrowLeftIcon />
            ) : (
              <KeyboardDoubleArrowRightIcon />
            )}
          </Button>
        </Stack>
        <Collapse in={showFilters} orientation="horizontal">
          <Stack sx={{ paddingLeft: '1rem', marginBottom: 2 }}>
            <FormControlLabel
              checked={dailyViewCombinedMedications}
              onChange={toggleDailyViewCombinedMedications}
              control={<Switch color="primary" />}
              label="Combine medications"
              data-cy="daily-view-filter-combine-medications"
            />
            <FormControlLabel
              checked={dailyViewFixedYAxis}
              onChange={toggleDailyViewFixedYAxis}
              control={<Switch color="primary" />}
              label="Fixed Y-Axis"
              data-cy="daily-view-filter-fixed-y-axis"
            />
            <FormControlLabel
              checked={dailyViewHideEmptyDays}
              onChange={toggleDailyViewHideEmptyDays}
              control={<Switch color="primary" />}
              label="Hide empty days"
              data-cy="daily-view-filter-hide-empty-days"
            />
          </Stack>

          <Text variant="head20B" component="h2" sx={{ paddingLeft: '1.8rem' }}>
            Filters
          </Text>
          <Stack spacing={0} sx={{ width: '25rem' }}>
            <DailyCategoryFilter
              {...{
                title: SECTION_TITLES.tremor,
                isCategoryActive: isTremorFilterActive,
                onToggleCategory: () =>
                  setIsTremorFilterActive(!isTremorFilterActive),
                disableFilter: !hasTremorData,
              }}
              data-cy="daily-view-filter-tremor"
            />
            <DailyCategoryFilter
              {...{
                title: SECTION_TITLES.dyskinesia,
                isCategoryActive: isDyskinesiaFilterActive,
                onToggleCategory: () =>
                  setIsDyskinesiaFilterActive(!isDyskinesiaFilterActive),
                disableFilter: !hasDyskinesiaData,
              }}
              data-cy="daily-view-filter-dyskinesia"
            />
            <DailyCategoryFilter
              {...{
                title: SECTION_TITLES.medication,
                isCategoryActive: isMedicationFilterActive,
                onToggleCategory: () =>
                  setIsMedicationFilterActive(!isMedicationFilterActive),
                initialFilters: medicationInitialFilters,
                activeFilters: medicationActiveFilters,
                onChange: (e) => setMedicationActiveFilters(e.target.value),
                disableFilter: !hasMedicationEvents,
              }}
              data-cy="daily-view-filter-medication"
            />
            <DailyCategoryFilter
              {...{
                title: SECTION_TITLES.symptoms,
                isCategoryActive: isSymptomFilterActive,
                onToggleCategory: () =>
                  setIsSymptomFilterActive(!isSymptomFilterActive),
                initialFilters: symptomInitialFilters,
                activeFilters: symptomActiveFilters,
                onChange: (e) => setSymptomActiveFilters(e.target.value),
                disableFilter: !hasSymptomEvents,
              }}
              data-cy="daily-view-filter-symptoms"
            />
            {/* <DailyCategoryFilter
            {...{
              title: SECTION_TITLES.sleep,
              isCategoryActive: isSleepFilterActive,
              onToggleCategory: () =>
                setIsSleepFilterActive(!isSleepFilterActive),
            }}
          /> */}
            <DailyCategoryFilter
              {...{
                title: SECTION_TITLES.checkin,
                isCategoryActive: isCheckinFilterActive,
                onToggleCategory: () =>
                  setIsCheckinFilterActive(!isCheckinFilterActive),
                disableFilter: !hasCheckinEvents,
              }}
              data-cy="daily-view-filter-checkin"
            />
            <DailyCategoryFilter
              {...{
                title: SECTION_TITLES.notes,
                isCategoryActive: isNotesFilterActive,
                onToggleCategory: () =>
                  setIsNotesFilterActive(!isNotesFilterActive),
                disableFilter: !hasNotesEvents,
              }}
              data-cy="daily-view-filter-notes"
            />
          </Stack>
        </Collapse>
      </Stack>
    </Stack>
  )
}

export default DailyFilters
