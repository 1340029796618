import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components'
import { ThemeProvider as MuiV5ThemeProvider } from '@mui/material/styles'
import {
  ThemeProvider as MuiV4ThemeProvider,
  withTheme as muiWithTheme,
} from '@material-ui/core'
import { ConfirmProvider } from 'material-ui-confirm'
import { clinicianThemeMUIV4 } from 'theme/clinicianThemeMUIV4'
import { clinicianTheme } from 'theme'

const MaterialUiTheme = ({ children }) => {
  return (
    <MuiV4ThemeProvider theme={clinicianThemeMUIV4}>
      <MuiV5ThemeProvider theme={clinicianTheme}>{children}</MuiV5ThemeProvider>
    </MuiV4ThemeProvider>
  )
}

const StyledComponentsTheme = ({ children }) => {
  return (
    <StyledComponentsThemeProvider theme={clinicianThemeMUIV4}>
      {children}
    </StyledComponentsThemeProvider>
  )
}

const StyledComponentsThemeWithMui = muiWithTheme(StyledComponentsTheme)

export const WithTheme = ({ children }) => (
  <MaterialUiTheme>
    <StyledComponentsThemeWithMui>
      <ConfirmProvider>{children}</ConfirmProvider>
    </StyledComponentsThemeWithMui>
  </MaterialUiTheme>
)
