export const clinicianRoutes = {
  OVERVIEW: 'overview',
  SUMMARY: 'summary',
  SUMMARY_COMPARISON: 'summaryComparison',
  DAILY: 'daily',
  LOG: 'log',
  REPORT_PATIENT: 'report',
  REPORT_CLINICIAN: 'reportClinician',
  SETTINGS: 'settings',
  ANSWER_QUESTION: 'questionAnswer',
  WEEKLY_VIEW: 'weeklyView',
}
