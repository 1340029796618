import moment from 'moment'
import { SECTION_TITLES } from 'ui/clinicianScreens/Patient/Daily/consts'
import { getEventName } from 'ui/clinicianScreens/Patient/helpers'
import { roundToNearestTenMinutes } from 'ui/clinicianScreens/Patient/Overview/helpers'
import { getChartIntervals } from 'ui/clinicianScreens/Patient/Report/helpers'
import { getDateFromTimestamp } from 'utilities/time'

export const getDataByDate = (data, date) =>
  data.filter((event) => {
    const { startTime } = event?.duration || {}
    const momentStartTime = moment.unix(startTime)
    return momentStartTime.isSame(date, 'day')
  })

const formatDailyEventsByBarChartIntervals = ({ item, data = [], label }) => {
  const chartIntervals = getChartIntervals()

  const formattedData = chartIntervals.map((timestamp) => ({
    timestamp,
    name: moment(timestamp, 'hh:mm A').format('h A'),
    title: item,
    classification: data[0]?.classification,
    [label]: 0,
  }))

  data.forEach((event, i) => {
    const index = formattedData.findIndex(
      (item) =>
        item.timestamp === moment(event.startTimeInterval).format('hh:mm A'),
    )

    for (let j = 0; j < event.durationIntervals; j++) {
      if (formattedData[index + j]) {
        formattedData[index + j][label] = 1
      }
    }
  })

  return formattedData
}

export const formatDailyEventsBarChartData = (
  { data, date, activeFilters, label, timezoneName },
  combineData,
) => {
  const dataByDate = getDataByDate(data, date)
  const formattedEventData = dataByDate.map((event) => {
    // get number of 10 minute intervals between startTimeInterval and endTimeInterval
    const { startTime, endTime } = event?.duration || {}
    const momentStartTime = getDateFromTimestamp(startTime, timezoneName)
    const momentEndTime = getDateFromTimestamp(endTime, timezoneName)
    const startTimeInterval = roundToNearestTenMinutes(momentStartTime)
    const endTimeInterval = roundToNearestTenMinutes(momentEndTime)
    const duration = moment.duration(endTimeInterval.diff(startTimeInterval))
    const durationIntervals = duration.asMinutes() / 10 || 1
    const title =
      label === SECTION_TITLES.medication
        ? getCombinedMedicationNameAndStrength(event)
        : getEventName(event)

    return {
      title,
      name: moment(startTimeInterval).format('h A'),
      timestamp: moment(startTimeInterval).format('hh:mm A'),
      startTimeInterval,
      durationIntervals,
      classification: event.classification.enum,
    }
  })

  const dataToParse = formattedEventData.reduce((acc, item) => {
    if (!acc[item.title]) {
      acc[item.title] = []
    }
    acc[item.title].push(item)
    return acc
  }, {})

  const formattedChartData = Object.entries(dataToParse).map(([key, value]) =>
    formatDailyEventsByBarChartIntervals({
      item: key,
      data: value,
      label,
    }),
  )

  const filteredData = formattedChartData.filter((item) =>
    activeFilters.includes(item[0].title),
  )

  const alphabeticallySortedData = filteredData.sort((a, b) =>
    a[0].title.localeCompare(b[0].title),
  )

  if (combineData) {
    const combinedData = alphabeticallySortedData.reduce((acc, item) => {
      if (!acc[0]) {
        acc[0] = item
      } else {
        acc[0].forEach((dataItem, i) => {
          dataItem.title = label
          dataItem[label] = dataItem[label] || 0
          if (item[i][label]) {
            dataItem[label] = 1
          }
        })
      }
      return acc
    }, [])

    return combinedData
  }

  return alphabeticallySortedData
}

export const getCombinedMedicationNameAndStrength = (event, phiVisibility) => {
  const name = getEventName(event)
  const { payload } = event
  const eventPayload = JSON.parse(payload)
  const dosage = eventPayload?.dosage
  const dosageStrengthQuantity = eventPayload?.dosage_strength?.quantity
  const dosageStrengthUnit = eventPayload?.dosage_strength?.unit
  let doseText =
    name === 'Custom Event' && !phiVisibility ? 'Custom Medication' : name

  if (dosage && dosageStrengthQuantity) {
    doseText += ` ${dosage} x ${dosageStrengthQuantity}${dosageStrengthUnit}`
  } else if (dosageStrengthQuantity) {
    doseText += ` ${dosageStrengthQuantity}${dosageStrengthUnit}`
  } else if (dosage) {
    doseText += ` ${dosage} pills`
  }

  return doseText
}
