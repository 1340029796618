import { formatBarChartData } from 'ui/clinicianScreens/Patient/Overview/helpers'
import { BarChart, Stack, Text } from 'ui/baseComponents'
import { colors } from 'theme/colors'
import useTremorAndDyskinesiaAggregate from 'ui/hooks/summaryAggregates/useTremorAndDyskinesiaAggregate'
import { TEN_MINUTES_IN_SECONDS } from 'utilities/time'
import { getCombinedMaxValue } from 'ui/clinicianScreens/Patient/Report/helpers'
import {
  SECTION_TITLES,
  mediumChartHeightWithAxis,
  mediumChartHeightWithoutAxis,
} from 'ui/clinicianScreens/Patient/Daily/consts'
import { memo, useEffect } from 'react'
import useBoundStore from 'domains/zustand/store'

const STYLES = {
  chartContainer: {
    position: 'relative',
  },
  whiteRectangle: {
    '&::after': {
      content: '""',
      position: 'absolute',
      left: '34px',
      right: 0,
      bottom: '-4px',
      backgroundColor: colors.WHITE,
      height: '39px',
      zIndex: 0,
    },
  },
  verticalText: {
    writingMode: 'vertical-rl',
    textOrientation: 'mixed',
    transform: 'rotate(180deg) translateY(50%)',
    whiteSpace: 'nowrap',
    position: 'absolute',
    top: '50%',
    transformOrigin: 'center',
  },
}

const DailyTremorDyskinesia = ({
  date,
  isTremorFilterActive,
  isDyskinesiaFilterActive,
  hasTremorDataForDay,
  setHasTremorDataForDay,
  hasDyskinesiaDataForDay,
  setHasDyskinesiaDataForDay,
  setIsTDLoading,
}) => {
  const dailyViewFixedYAxis = useBoundStore(
    (state) => state.dailyViewFixedYAxis,
  )
  const { data, isLoading } = useTremorAndDyskinesiaAggregate(
    date,
    date,
    TEN_MINUTES_IN_SECONDS,
  )
  const { tremor: tremorData, dyskinesia: dyskinesiaData } = data

  useEffect(() => {
    setIsTDLoading(isLoading)

    if (tremorData?.numberOfDaysWithData) {
      setHasTremorDataForDay(true)
    }
    if (dyskinesiaData?.numberOfDaysWithData) {
      setHasDyskinesiaDataForDay(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tremorData, dyskinesiaData, isLoading])

  const tremorLegendItem = {
    name: SECTION_TITLES.tremor,
    color: colors.TREMOR[600],
  }

  const dyskinesiaLegendItem = {
    name: SECTION_TITLES.dyskinesia,
    color: colors.DYSKINESIA[500],
  }

  const formattedTremorData = formatBarChartData(
    tremorData,
    SECTION_TITLES.tremor,
  )
  const formattedDyskinesiaData = formatBarChartData(
    dyskinesiaData,
    SECTION_TITLES.dyskinesia,
  )

  const yAxisMax = dailyViewFixedYAxis
    ? 10
    : getCombinedMaxValue(tremorData, dyskinesiaData)

  const showTremorChart = isTremorFilterActive && hasTremorDataForDay
  const showDyskinesiaChart =
    isDyskinesiaFilterActive && hasDyskinesiaDataForDay

  return (
    <>
      {showTremorChart && (
        <Stack
          spacing={0}
          sx={{
            ...STYLES.chartContainer,
            ...(!showDyskinesiaChart ? STYLES.whiteRectangle : {}),
          }}
          data-cy="daily-view-section-tremor"
        >
          <Text variant="body12" sx={STYLES.verticalText}>
            {SECTION_TITLES.tremor}
          </Text>
          <BarChart
            {...{
              title: SECTION_TITLES.tremor,
              data: formattedTremorData,
              legendItem: tremorLegendItem,

              xAxisInterval: 11,
              yAxisInterval: 1,
              yAxisMax,
              yAxisUnit: ' m',
              hideVerticalGridLines: true,
              showInactiveBackground: true,
              chartId: `daily-chart-tremor`,
              containerStyles: { zIndex: 1 },
              ...(showDyskinesiaChart
                ? { hideXAxis: true, height: mediumChartHeightWithoutAxis }
                : {
                    hideXAxisTickLine: true,
                    hideVerticalGridLines: true,
                    height: mediumChartHeightWithAxis,
                  }),
            }}
          />
        </Stack>
      )}

      {showDyskinesiaChart && (
        <Stack
          sx={{ ...STYLES.chartContainer, ...STYLES.whiteRectangle }}
          data-cy="daily-view-section-dyskinesia"
        >
          <Text variant="body12" sx={STYLES.verticalText}>
            {SECTION_TITLES.dyskinesia}
          </Text>
          <BarChart
            {...{
              title: SECTION_TITLES.dyskinesia,
              data: formattedDyskinesiaData,
              legendItem: dyskinesiaLegendItem,
              height: mediumChartHeightWithAxis,
              xAxisInterval: 11,
              yAxisInterval: 1,
              yAxisMax,
              yAxisUnit: ' m',
              hideXAxisTickLine: true,
              hideVerticalGridLines: true,
              showInactiveBackground: true,
              chartId: `daily-chart-dyskinesia`,
              containerStyles: { zIndex: 1 },
            }}
          />
        </Stack>
      )}
    </>
  )
}

export default memo(DailyTremorDyskinesia)
