import { useContext, useEffect, useState } from 'react'
import { Navigate, Outlet, useLocation, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import mixpanel from 'mixpanel-browser'
import moment from 'moment'
import { Search } from '@material-ui/icons'
import { useFlags } from 'domains/launchdarkly/hooks'
import { useLogger } from 'domains/logger/context'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import { GET_ME_QUERY } from 'domains/carrotGraph/queries'
import { WithTheme } from 'app/WithTheme'
import { MaintenanceNotification } from 'ui/clinicianScreens/Maintenance/MaintenanceNotification'
import ErrorMessage from 'ui/components/ErrorMessage'
import { get } from 'nested-property'
import { useSelector } from 'react-redux'
import { getUser } from 'domains/auth/reducer'
import {
  CognitoUserPoolContext,
  ConfigContext,
  MembershipContext,
  OrgContext,
  UserContext,
  UserPHIContext,
} from 'ui/contexts'
import { SECOND_IN_MILLISECONDS } from 'utilities/time'
import {
  identifyLoggedInUserForTracking,
  registerMixpanelSuperProperties,
  setPropertiesOnUserProfile,
} from 'domains/mixpanel'
import { ANALYTICS_TRACKING_STATUS } from 'domains/mixpanel/consts'
import '../../variables.css'
import { AccessDeniedNotification } from 'ui/clinicianScreens/Maintenance/AccessDeniedNotification'
import { getAutomatedUserJwt } from 'utilities/automatedUser'
import AutomatedReport from './AutomatedReport'
import { PatientProvider } from 'ui/contexts/PatientContext'
import { usePatient } from './Patient/queries'
import {
  COGNITO_ATTR_DEFAULT_LOGIN_PORTAL,
  DEFAULT_LOGIN_PORTAL_STRIVE_STUDY_REQUIRED,
  STRIVE_STUDY_URL,
} from 'ui/screens/SignIn/consts'
/**
 * Function that fetches further pages of memberships based on a cursor
 *
 * @param {function} fetchMore the function to append to the user query
 * @param {boolean} cursor the cursor indicating the page depth of our query
 */

export const fetchAllMemberships = (fetchMore, cursor) => {
  fetchMore({
    variables: {
      cursor,
    },
    updateQuery: (previousResult, { fetchMoreResult }) => {
      // isolates the list to which we are appending
      const prevMemberships =
        get(previousResult, 'user.membershipList.memberships') || []
      const newMemberships =
        get(fetchMoreResult, 'user.membershipList.memberships') || []
      // spread operator used repeatedly to append data to only the correct list.
      // A little bit verbose at this depth but necessary for our db structure.
      return {
        ...fetchMoreResult,
        user: {
          ...fetchMoreResult.user,
          membershipList: {
            ...fetchMoreResult.user.membershipList,
            memberships: [...prevMemberships, ...newMemberships],
          },
        },
      }
    },
  })
}

const automatedUserJwt = getAutomatedUserJwt()

// Refetch query for User (used when switching orgs)
export const useRefetchMe = () => ({ query: GET_ME_QUERY })

/**
 * @returns {JSX.Element} App view for users with active sessions.
 */
function Root() {
  const [loadingComplete, setLoadingComplete] = useState(false)
  const [mixpanelLoaded, setMixpanelLoaded] = useState(false)
  const { vendors = {} } = useContext(ConfigContext)
  const { blockedUser, dhpMaintenanceUpcoming } = useFlags()
  const { patientId } = useParams()
  const { loading: patientLoading, patient } = usePatient(patientId)
  const { pathname } = useLocation()
  const logger = useLogger()
  const ldClient = useLDClient()
  const cognitoUserPool = useContext(CognitoUserPoolContext)
  const user = useSelector(getUser)

  const {
    data: me,
    loading,
    error,
    fetchMore,
  } = useQuery(GET_ME_QUERY, { skip: !!automatedUserJwt })

  const membershipsCursor = get(me, 'user.membershipList.pageInfo.endCursor')
  const phiVisibility =
    !!automatedUserJwt || !!me?.user?.defaultMembership?.role?.canSeePHI

  useEffect(() => {
    if (me?.user?.membershipList && membershipsCursor) {
      fetchAllMemberships(fetchMore, membershipsCursor)
    }
  }, [fetchMore, me?.user?.membershipList, membershipsCursor])

  useEffect(() => {
    /*
     * Configure integrations that expect session user context
     */
    if (!me?.user) {
      return
    }

    /* Initialize LaunchDarkly with user */
    ldClient
      .identify({
        key: me.user.username,
        name: me.user.displayName,
        email: me.user.email,
      })
      .then(() => {
        // Make sure that the flag controlling the clinician portal is grabbed before loading the theme/page
        setLoadingComplete(true)
      })

    /* Initialize Mixpanel
        There is a Cypress bug that makes Cypress crash when cy.clock is used with mixpanel.
        See: https://github.com/cypress-io/cypress/issues/23370
        Until the bug is resolved, we need to make sure we're not in Cypress whenever we call a method on mixpanel.
    */
    if (!window.Cypress) {
      mixpanel.init(vendors.mixpanel.projectToken, {
        debug: process.env.NODE_ENV === 'development',
        loaded: setMixpanelLoaded(true),
      })
    }
  }, [me, ldClient, vendors.mixpanel, vendors.segment, setLoadingComplete])

  useEffect(() => {
    if (!me?.user) {
      return
    }

    const {
      user: { analyticsTracking },
    } = me

    if (
      !window.Cypress &&
      analyticsTracking === ANALYTICS_TRACKING_STATUS.optedIn
    ) {
      // Opt the user in to data tracking and cookies/localstorage for this Mixpanel instance
      mixpanel.opt_in_tracking()

      identifyLoggedInUserForTracking(me.user.id)
      registerMixpanelSuperProperties(me.user)
      setPropertiesOnUserProfile(me.user)
    }

    if (
      !window.Cypress &&
      (analyticsTracking === ANALYTICS_TRACKING_STATUS.noDecision ||
        analyticsTracking === ANALYTICS_TRACKING_STATUS.optedOut)
    ) {
      /*  Opt the user out of data tracking and cookies/localstorage for this Mixpanel instance.
          If we don't explicitly opt out the user, when we call mixpanel.track we'll see bugs/pages breaking. */
      mixpanel.opt_out_tracking()
    }
  }, [me])

  // This useEffect enables us to track page views throughout the app
  useEffect(() => {
    if (!mixpanelLoaded) {
      return
    }

    let path = pathname

    const regexPatternWithNestedRoutes = /\/patients\/[^/]*\//
    const regexPatternWithoutNestedRoutes = /\/patients\/.*?.*/

    if (pathname.startsWith('/patients/')) {
      path = pathname.match(regexPatternWithNestedRoutes)
        ? pathname.replace(regexPatternWithNestedRoutes, '/patients/id/')
        : pathname.replace(regexPatternWithoutNestedRoutes, '/patients/id?')
    }

    mixpanel.track('Page View', {
      description:
        'tracks when a user navigates to a new page. Can see which page the user has visited.',
      page: path,
    })
  }, [mixpanelLoaded, pathname])

  if (
    user &&
    user[COGNITO_ATTR_DEFAULT_LOGIN_PORTAL] ===
      DEFAULT_LOGIN_PORTAL_STRIVE_STUDY_REQUIRED
  ) {
    window.location.href = STRIVE_STUDY_URL
    return
  }

  if (error && !automatedUserJwt) {
    logger.error(error)

    const ERROR_401 = 401
    const ERROR_403 = 403

    if (
      error.networkError &&
      (error.networkError.statusCode === ERROR_401 ||
        error.networkError.statusCode === ERROR_403)
    ) {
      return <Navigate to="signin" replace />
    } else {
      return <ErrorMessage icon={Search} />
    }
  }

  if (automatedUserJwt) {
    if (patientLoading) {
      return null
    }
  } else {
    if (
      !cognitoUserPool.getCurrentUser() ||
      !me?.user ||
      loading ||
      loadingComplete === false
    ) {
      return null
    }
  }

  const startTime = new Date(
    moment(dhpMaintenanceUpcoming.start_time * SECOND_IN_MILLISECONDS),
  )
  const endTime = new Date(
    moment(dhpMaintenanceUpcoming.end_time * SECOND_IN_MILLISECONDS),
  )

  const referrer = sessionStorage.getItem('referrer')
  if (referrer) {
    sessionStorage.removeItem('referrer')
    return <Navigate to={referrer} />
  }

  return automatedUserJwt ? (
    <UserPHIContext.Provider value={true}>
      <WithTheme>
        <PatientProvider value={patient}>
          <AutomatedReport />
        </PatientProvider>
      </WithTheme>
    </UserPHIContext.Provider>
  ) : (
    <UserContext.Provider value={me.user}>
      <MembershipContext.Provider value={me.user.defaultMembership}>
        <OrgContext.Provider value={me.user.defaultMembership.org}>
          <UserPHIContext.Provider value={phiVisibility}>
            <WithTheme>
              {new Date() >= startTime && new Date() < endTime ? (
                <MaintenanceNotification endTime={endTime} />
              ) : blockedUser ? (
                <AccessDeniedNotification />
              ) : (
                <Outlet />
              )}
            </WithTheme>
          </UserPHIContext.Provider>
        </OrgContext.Provider>
      </MembershipContext.Provider>
    </UserContext.Provider>
  )
}

export default Root
