import { Button } from 'ui/components/Button'
import useBoundStore from 'domains/zustand/store'

const VIEW_PREFERENCE_BUTTON_TEXT = {
  searchPatients: 'Search Patients',
  viewAll: 'View All',
}

/**
 * Renders a button that toggles between showing all patients and searching for patients.
 * @returns {JSX.Element} - The rendered button component.
 */
const ViewPreferenceButton = () => {
  const showAllPatients = useBoundStore((state) => state.showAllPatients)
  const toggleAllPatients = useBoundStore((state) => state.toggleAllPatients)
  const resetPatientList = useBoundStore((state) => state.resetPatientList)
  const resetSearchPatients = useBoundStore(
    (state) => state.resetSearchPatients,
  )
  const setSearchPatientsExecuted = useBoundStore(
    (state) => state.setSearchPatientsExecuted,
  )

  const onButtonClick = () => {
    toggleAllPatients()
    resetPatientList() // This clears the search input when toggling between recent and all patients
    resetSearchPatients()
    setSearchPatientsExecuted(false)
  }

  return (
    <Button variant="contained" color="primary" onClick={() => onButtonClick()}>
      {showAllPatients
        ? VIEW_PREFERENCE_BUTTON_TEXT.searchPatients
        : VIEW_PREFERENCE_BUTTON_TEXT.viewAll}
    </Button>
  )
}
export default ViewPreferenceButton
