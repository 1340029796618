import useBoundStore from 'domains/zustand/store'
import moment from 'moment'
import { memo, useEffect } from 'react'
import { colors } from 'theme/colors'
import { Button, Stack, Text } from 'ui/baseComponents'
import { SECTION_TITLES } from 'ui/clinicianScreens/Patient/Daily/consts'
import { getEventName } from 'ui/clinicianScreens/Patient/helpers'
import { CLINICIAN_RED_FLAGS } from 'ui/clinicianScreens/Patient/Report/sections/SymptomLog/consts'
import { RedFlagIcon } from 'ui/components/RedFlagIcon'

const DailySummary = ({ data }) => {
  const addDailyViewRedFlagDates = useBoundStore(
    (state) => state.addDailyViewRedFlagDates,
  )
  const redFlagSymptoms = [...data].filter((item) =>
    CLINICIAN_RED_FLAGS.includes(item.classification.enum),
  )

  const redFlagSymptomsAndDatesBySymptomName = redFlagSymptoms.reduce(
    (acc, item) => {
      const symptomName = getEventName(item)
      const date = moment.unix(item.duration.startTime).format('YYYY-MM-DD')
      if (!acc[symptomName]) {
        acc[symptomName] = []
      }
      if (!acc[symptomName].includes(date)) {
        acc[symptomName].push(date)
      }
      return acc
    },
    {},
  )

  // sort dates in descending order
  Object.entries(redFlagSymptomsAndDatesBySymptomName).forEach(([, dates]) =>
    dates.sort((a, b) => moment(b).diff(a)),
  )

  const handleDateClick = (date) => {
    const element = document.getElementById(date)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const hasRedFlagSymptoms =
    Object.keys(redFlagSymptomsAndDatesBySymptomName).length > 0

  const redFlagDates = redFlagSymptoms?.map((item) =>
    moment.unix(item.duration.startTime).format('YYYY-MM-DD'),
  )

  useEffect(() => {
    if (redFlagDates.length > 0) {
      addDailyViewRedFlagDates(redFlagDates)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redFlagSymptoms.length])

  return hasRedFlagSymptoms ? (
    <Stack
      data-cy="daily-view-section-red-flag-summary"
      sx={{
        padding: '1rem',
        border: '2px solid',
        borderColor: colors.RED[500],
      }}
    >
      <Text variant="body16B" component="h2">
        <RedFlagIcon /> {SECTION_TITLES.summary}
      </Text>

      <Stack>
        {Object.entries(redFlagSymptomsAndDatesBySymptomName).map(
          ([name, dates], i) => (
            <Stack key={i} direction="row" alignItems="center">
              <Text variant="body14B">{name}: </Text>
              <Stack direction="row" gap={1}>
                {dates.map((date) => (
                  <Button
                    key={`summary-date-${date}`}
                    type="secondary"
                    size="small"
                    onClick={() => handleDateClick(date)}
                    color="error"
                    data-cy="daily-view-red-flag-date-button"
                  >
                    {moment(date).format('MMM DD')}
                  </Button>
                ))}
              </Stack>
            </Stack>
          ),
        )}
      </Stack>
    </Stack>
  ) : null
}

export default memo(DailySummary)
