import { memo, useEffect, useState } from 'react'
import { Text, Stack } from 'ui/baseComponents'
import { RedFlagIcon } from 'ui/components/RedFlagIcon'
import DailyTremorDyskinesia from 'ui/clinicianScreens/Patient/Daily/sections/DailyTremorDyskinesia/DailyTremorDyskinesia'
import DailyMedication from 'ui/clinicianScreens/Patient/Daily/sections/DailyMedication/DailyMedication'
import DailySymptoms from 'ui/clinicianScreens/Patient/Daily/sections/DailySymptoms/DailySymptoms'
import DailyNotes from 'ui/clinicianScreens/Patient/Daily/sections/DailyNotes/DailyNotes'
import DailyCheckinSymptoms from 'ui/clinicianScreens/Patient/Daily/sections/DailyCheckinSymptoms/DailyCheckinSymptoms'
import { colors } from 'theme/colors'
import useBoundStore from 'domains/zustand/store'
import { DAILY_EMPTY_DAY_TEXT } from 'ui/clinicianScreens/Patient/Daily/consts'
import moment from 'moment'

const STYLES = {
  titleContainer: {
    backgroundColor: colors.COOL[100],
    padding: '1rem 0.5rem',
  },
  title: {
    writingMode: 'vertical-rl',
    transform: 'rotate(180deg)',
  },
  content: {
    padding: '0 1rem',
    flex: 1,
  },
  chartContainer: {
    position: 'relative',
  },
  chartBackground: {
    position: 'absolute',
    top: '1rem',
    bottom: '1.8rem',
    left: '74.3px',
    right: '11.7px',
    backgroundImage: `repeating-linear-gradient(to right, transparent, transparent 0px, ${colors.TREMOR[100]} 0px, ${colors.TREMOR[100]} 1px, white 1px, white calc(100% / 24))`,
    zIndex: 1,

    '&::after': {
      content: '""',
      position: 'absolute',
      top: '0',
      bottom: '0',
      left: '0',
      right: '0',
      backgroundImage: `repeating-linear-gradient(to right, transparent, transparent 0px, ${colors.TREMOR[100]} 0px, ${colors.TREMOR[100]} 2px, transparent 2px, transparent calc(100% / 12))`,
      zIndex: 2,
    },
  },
  chartContent: {
    position: 'relative',
    zIndex: 3,
    overflow: 'hidden',
    paddingBottom: '4px',
  },
}

const DailyDay = ({
  date,
  medicationEvents,
  medicationActiveFilters,
  isMedicationFilterActive,
  symptomEvents,
  symptomActiveFilters,
  isSymptomFilterActive,
  setHasTremorData,
  isTremorFilterActive,
  setHasDyskinesiaData,
  isDyskinesiaFilterActive,
  checkinEvents,
  isCheckinFilterActive,
  notesEvents,
  isNotesFilterActive,
}) => {
  const dailyViewRedFlagDates = useBoundStore(
    (state) => state.dailyViewRedFlagDates,
  )
  const dailyViewHideEmptyDays = useBoundStore(
    (state) => state.dailyViewHideEmptyDays,
  )
  const [isTDLoading, setIsTDLoading] = useState(true)
  const [hasTremorDataForDay, setHasTremorDataForDay] = useState(false)
  const [hasDyskinesiaDataForDay, setHasDyskinesiaDataForDay] = useState(false)
  const [hasSymptomDataForDay, setHasSymptomDataForDay] = useState(false)
  const [hasMedicationDataForDay, setHasMedicationDataForDay] = useState(false)
  const [hasCheckinDataForDay, setHasCheckinDataForDay] = useState(false)
  const [hasNotesDataForDay, setHasNotesDataForDay] = useState(false)

  useEffect(() => {
    if (hasTremorDataForDay) {
      setHasTremorData(true)
    }
    if (hasDyskinesiaDataForDay) {
      setHasDyskinesiaData(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasTremorDataForDay, hasDyskinesiaDataForDay, isTDLoading])

  const isEmpty =
    !isTDLoading &&
    !hasMedicationDataForDay &&
    !hasSymptomDataForDay &&
    !hasTremorDataForDay &&
    !hasDyskinesiaDataForDay &&
    !hasCheckinDataForDay &&
    !hasNotesDataForDay

  return dailyViewHideEmptyDays && isEmpty ? null : (
    <Stack id={date} key={date} direction="row" data-cy="daily-view-day">
      <Stack
        alignItems="center"
        justifyContent="center"
        spacing={1}
        sx={STYLES.titleContainer}
      >
        {dailyViewRedFlagDates.has(date) && <RedFlagIcon />}
        <Text
          variant="body16B"
          component="h1"
          sx={STYLES.title}
          key={`${date}-title`}
        >
          {moment(date).format('MMM DD')}
        </Text>
      </Stack>
      {isEmpty ? (
        <Stack
          key={`${date}-empty-content`}
          justifyContent="center"
          spacing={2}
          sx={STYLES.content}
          data-cy="daily-view-empty-day"
        >
          <Text variant="body14" color={colors.GREY[700]}>
            {DAILY_EMPTY_DAY_TEXT}
          </Text>
        </Stack>
      ) : (
        <Stack key={`${date}-content`} spacing={2} sx={STYLES.content}>
          <Stack sx={STYLES.chartContainer}>
            <Stack sx={STYLES.chartBackground} />
            <Stack sx={STYLES.chartContent}>
              {isMedicationFilterActive && (
                <DailyMedication
                  {...{
                    date,
                    data: medicationEvents,
                    activeFilters: medicationActiveFilters,
                    setHasDataForDay: setHasMedicationDataForDay,
                  }}
                />
              )}
              {isSymptomFilterActive && (
                <DailySymptoms
                  {...{
                    date,
                    data: symptomEvents,
                    activeFilters: symptomActiveFilters,
                    setHasDataForDay: setHasSymptomDataForDay,
                  }}
                />
              )}
              <DailyTremorDyskinesia
                {...{
                  date,
                  isTremorFilterActive,
                  isDyskinesiaFilterActive,
                  hasTremorDataForDay,
                  setHasTremorDataForDay,
                  hasDyskinesiaDataForDay,
                  setHasDyskinesiaDataForDay,
                  setIsTDLoading,
                }}
              />
              {/* {isSleepFilterActive && (
                    <DailySleep {...{ date, data: sleepEvents }} />
                  )} */}
            </Stack>
          </Stack>
          {isCheckinFilterActive && (
            <DailyCheckinSymptoms
              {...{
                date,
                data: checkinEvents,
                setHasDataForDay: setHasCheckinDataForDay,
              }}
            />
          )}
          {isNotesFilterActive && (
            <DailyNotes
              {...{
                date,
                data: notesEvents,
                setHasDataForDay: setHasNotesDataForDay,
              }}
            />
          )}
        </Stack>
      )}
    </Stack>
  )
}

export default memo(DailyDay)
