export const calculateShowingConditions = ({
  loading,
  error,
  patients,
  searchPatientsExecuted,
}) => ({
  allPatientsLoaded: !loading && !error && patients?.length > 0,
  always: true,
  noPatients: !loading && !error && !patients?.length,
  searchPatients: !searchPatientsExecuted,
})
