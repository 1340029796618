import { useMemo } from 'react'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import {
  STYLES,
  TABLE_HEADERS,
  TABLE_STATUS_TEXT,
} from 'ui/screens/Patients/PatientList/PatientTable/consts'
import { Text } from 'ui/baseComponents'
import {
  PatientTableLoading,
  PatientTableEmpty,
  PatientTableError,
  TableCellLinkToPatientSettings,
  TableCellLinkToPatientReport,
  TableCellHeader,
  TableCellPatientName,
} from 'ui/screens/Patients/PatientList/PatientTable'
import { calculateShowingConditions } from 'ui/screens/Patients/PatientList/PatientTable/helpers'
import { useFlags } from 'domains/launchdarkly/hooks'
import { usePatientList, usePatientSearch } from 'ui/hooks'
import useBoundStore from 'domains/zustand/store'

/**
 * The patient table component for users with access to PHI.
 * @returns {JSX.Element} The rendered component.
 */
const PatientTableWithPHIAccess = () => {
  const errorPatientList = useBoundStore((state) => state.errorPatientList)
  const loadingPatientList = useBoundStore((state) => state.loadingPatientList)
  const patientListResults = useBoundStore((state) => state.patients)
  const showAllPatients = useBoundStore((state) => state.showAllPatients)

  const searchPatientsExecuted = useBoundStore(
    (state) => state.searchPatientsExecuted,
  )
  const searchPatientsError = useBoundStore(
    (state) => state.searchPatientsError,
  )
  const searchPatientsLoading = useBoundStore(
    (state) => state.searchPatientsLoading,
  )
  const searchPatientsResults = useBoundStore(
    (state) => state.searchPatientsResults,
  )

  const error = !showAllPatients ? searchPatientsError : errorPatientList
  const loading = !showAllPatients ? searchPatientsLoading : loadingPatientList
  const patients = !showAllPatients ? searchPatientsResults : patientListResults

  const { monthlyReportsTabVisibile: monthlyReportsTabVisible } = useFlags()

  usePatientList({
    fetchAll: true,
    skip: !showAllPatients,
  })

  usePatientSearch({ skip: showAllPatients })

  const SHOWING_CONDITIONS = useMemo(
    () =>
      calculateShowingConditions({
        loading,
        error,
        patients,
        searchPatientsExecuted,
      }),
    [loading, error, patients, searchPatientsExecuted],
  )

  return (
    <Table
      aria-label="Patient List"
      data-cy="patient-table-with-phi-access"
      data-testid="patient-table-with-phi-access"
    >
      <TableHead>
        <TableRow>
          {SHOWING_CONDITIONS.always && (
            <TableCellHeader sx={STYLES.nameCellClinicianPortal}>
              {TABLE_HEADERS.patient}
            </TableCellHeader>
          )}
          {SHOWING_CONDITIONS.always && (
            <TableCellHeader sx={STYLES.emailCell}>
              {TABLE_HEADERS.email}
            </TableCellHeader>
          )}
          {SHOWING_CONDITIONS.always && (
            <TableCellHeader sx={STYLES.gapColumn}></TableCellHeader>
          )}
          {SHOWING_CONDITIONS.always && (
            <TableCellHeader sx={STYLES.iconCell}>
              {TABLE_HEADERS.settings}
            </TableCellHeader>
          )}
          {monthlyReportsTabVisible && (
            <TableCellHeader sx={STYLES.iconCell}>
              {TABLE_HEADERS.report}
            </TableCellHeader>
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {loading && <PatientTableLoading />}
        {error && <PatientTableError error={error} />}
        {!showAllPatients && SHOWING_CONDITIONS.searchPatients && (
          <PatientTableEmpty icon="search" message={TABLE_STATUS_TEXT.search} />
        )}
        {(!SHOWING_CONDITIONS.searchPatients || showAllPatients) &&
          SHOWING_CONDITIONS.noPatients && <PatientTableEmpty />}
        {SHOWING_CONDITIONS.allPatientsLoaded &&
          patients.map((patient) => {
            const { identifiableInfo, id, preComputedName } = patient
            const { email } = identifiableInfo || {}
            return (
              <TableRow key={id} data-cy={`patient-row-${id}`}>
                <TableCellPatientName
                  patientId={id}
                  patientName={preComputedName}
                  sx={STYLES.nameCellClinicianPortal}
                />
                {SHOWING_CONDITIONS.always && (
                  <TableCell
                    sx={STYLES.emailCell}
                    data-cy="patient-row-patient-email"
                  >
                    <Text variant="body14">{email}</Text>
                  </TableCell>
                )}
                {SHOWING_CONDITIONS.always && (
                  <TableCell sx={STYLES.gapColumn}></TableCell>
                )}
                {SHOWING_CONDITIONS.always && (
                  <TableCellLinkToPatientSettings patientId={id} />
                )}
                {monthlyReportsTabVisible && (
                  <TableCellLinkToPatientReport patientId={id} />
                )}
              </TableRow>
            )
          })}
      </TableBody>
    </Table>
  )
}
export default PatientTableWithPHIAccess
