import { Stack } from 'ui/baseComponents'
import { PatientSearchInputDeprecated } from 'ui/screens/Patients/PatientList/PatientTable'

/**
 * Represents the toolbar component with actions a user can take regarding the patient table.
 * @returns {JSX.Element} The rendered toolbar component.
 */
const PatientTableToolbar = ({ searchComponent }) => (
  <Stack
    direction="row"
    alignItems="center"
    justifyContent={'space-between'}
    spacing={2}
    sx={{ paddingTop: '2rem' }}
  >
    {searchComponent || <PatientSearchInputDeprecated />}
  </Stack>
)

export default PatientTableToolbar
