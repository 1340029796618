import AccessTokens from 'ui/screens/Settings/AccessTokens'
import Clients from 'ui/screens/EditPatient/Clients'
import CookiePreferences from 'ui/screens/Settings/CookiePreferences'
import { Daily } from 'ui/clinicianScreens/Patient/Daily'
import Devices from 'ui/screens/EditPatient/Devices'
import EditDevice from 'ui/screens/EditPatient/Devices/EditDevice'
import EditPatient from 'ui/screens/EditPatient'
import { Log } from 'ui/clinicianScreens/Patient/Log'
import { Overview } from 'ui/clinicianScreens/Patient/Overview'
import Patient from 'ui/screens/Patient'
import PatientDetails from 'ui/screens/EditPatient/PatientDetails/PatientDetails'
import PatientList from 'ui/screens/Patients/PatientList'
import Profile from 'ui/screens/Settings/Profile'
import Settings from 'ui/screens/Settings'
import SignInWrapper from 'ui/screens/SignIn/SignInWrapper'
import { Summary } from 'ui/clinicianScreens/Patient/Summary'
import TaskNotifications from 'ui/screens/Patients/TaskNotifications'
import Users from 'ui/screens/Users'
import { Report } from 'ui/clinicianScreens/Patient/Report'
import ReportPatientPDFTest from 'ui/clinicianScreens/Patient/Report/ReportPatientPDFTest'
import { StriveGPT } from 'ui/clinicianScreens/Patient/StriveGPT'
import { WeeklyView } from 'ui/clinicianScreens/Patient/WeeklyView'

export const CLINICIAN_ROUTES = {
  daily: {
    path: 'daily',
    element: <Daily />,
    title: 'Daily View',
  },
  log: {
    path: 'log',
    element: <Log />,
    title: 'Patient Log',
  },
  overview: {
    path: 'overview',
    element: <Overview />,
    title: 'Overview',
  },
  questionAnswer: {
    path: 'questionAnswer',
    element: <StriveGPT />,
    title: 'PD Question Answering',
  },
  reportClinician: {
    path: 'reportClinician',
    element: <Report isClinicianReport />,
    title: 'Clinician Report',
  },
  reportPatient: {
    path: 'report',
    element: <Report />,
    title: 'Patient Report',
  },
  weeklyView: {
    path: 'weeklyView',
    element: <WeeklyView />,
    title: 'Weekly View',
  },
  reportPatientPDFTest: {
    path: 'reportPatientPDFTest',
    element: <ReportPatientPDFTest />,
    title: 'Test Patient Report PDF',
  },
  summary: {
    path: 'summary',
    element: <Summary />,
    title: 'Detail View',
  },
  summaryComparison: {
    path: 'summaryComparison',
    element: <Summary />,
    title: 'Detail View',
  },
}

export const SHARED_ROUTES = {
  accessTokens: {
    path: 'access-tokens',
    element: <AccessTokens />,
    title: 'Access Tokens',
  },
  clients: {
    path: 'clients',
    element: <Clients />,
  },
  cookiePreferences: {
    path: 'cookie-preferences',
    element: <CookiePreferences />,
    title: 'User Settings',
  },
  details: {
    path: 'details',
    element: <PatientDetails />,
  },
  deviceId: {
    path: ':deviceId',
    element: <EditDevice />,
  },
  devices: {
    path: 'devices',
    element: <Devices />,
  },
  editPatient: {
    path: 'manage',
    element: EditPatient,
  },
  patientId: {
    path: ':patientId',
    element: <Patient />,
  },
  patients: {
    path: '/patients',
    element: <PatientList />,
    title: 'All Patients',
  },
  profile: {
    path: 'profile',
    element: <Profile />,
    title: 'User Settings',
  },
  settings: {
    path: '/settings',
    element: <Settings />,
    title: 'User Settings',
  },
  signin: {
    path: '/signin',
    element: <SignInWrapper />,
  },
  taskNotifications: {
    path: 'task-notifications',
    element: <TaskNotifications />,
  },
  users: {
    path: '/users',
    element: <Users />,
    title: 'Team',
  },
}
