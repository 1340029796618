import { Stack, Text } from 'ui/baseComponents'
import { getDataByDate } from 'ui/clinicianScreens/Patient/Daily/sections/helpers'
import DailyChartTitle from 'ui/clinicianScreens/Patient/Daily/components/DailyChartTitle/DailyChartTitle'
import {
  convertUnixTimestampToSpecifiedTimezone,
  SECOND_IN_MILLISECONDS,
} from 'utilities/time'
import { memo, useContext, useEffect } from 'react'
import { DateRangeContext } from 'ui/contexts/DateRangeContext'
import { SECTION_TITLES } from 'ui/clinicianScreens/Patient/Daily/consts'
import moment from 'moment'

const DailyNotes = ({ date, data = [], setHasDataForDay = () => {} }) => {
  const { selectedTimezone } = useContext(DateRangeContext)

  const dataByDate = getDataByDate(data, date)

  const formattedNoteData = dataByDate.map((event) => {
    const time = convertUnixTimestampToSpecifiedTimezone({
      timestamp: event.duration.startTime * SECOND_IN_MILLISECONDS,
      timezoneName: selectedTimezone,
      format: 'hh:mm A',
    })

    const content = Object.entries(JSON.parse(event.payload)).map(
      (item) => item[1],
    )

    return {
      time,
      content,
    }
  })

  useEffect(() => {
    if (dataByDate.length > 0) {
      setHasDataForDay(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataByDate.length])

  return dataByDate.length > 0 ? (
    <Stack data-cy="daily-view-section-notes">
      <DailyChartTitle variant="body16B" component="h2">
        {SECTION_TITLES.notes}
      </DailyChartTitle>
      {formattedNoteData.map((note, i) => (
        <Text
          key={`daily-note-${moment(date).format('MM DD')}-${i}`}
          variant="body14"
          component="p"
        >
          <Text variant="body14B">{note.time}: </Text>
          {note.content}
        </Text>
      ))}
    </Stack>
  ) : null
}

export default memo(DailyNotes)
